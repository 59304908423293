// modal.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class ModalService {

  private modals: { [id: string]: BehaviorSubject<boolean> } = {};

  private modalDataSubject = new BehaviorSubject<{ show: boolean, component: any }>({ show: false, component: null });
  modalData$ = this.modalDataSubject.asObservable();


  public openModal(id: string): void {
    if (!this.modals[id]) {
      this.modals[id] = new BehaviorSubject<boolean>(false);
    }
    this.modals[id].next(true);
  }

  public closeModal(id: string): void {
    if (this.modals[id]) {
      this.modals[id].next(false);
    }
  }

  public getModalState(id: string): Observable<boolean> {
    if (!this.modals[id]) {
      this.modals[id] = new BehaviorSubject<boolean>(false);
    }
    return this.modals[id].asObservable();
  }


 

  open(component: any): void {
    this.modalDataSubject.next({ show: true, component });
  }

  close(): void {
    this.modalDataSubject.next({ show: false, component: null });
  }
}